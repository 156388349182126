@import url(./Variables.css);

.profile-page-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.profile-page-head .profile-page-header {
  width: auto;
}

.profile-page-head-ul .profile-page-head-avatar .img-sec {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}

.profile-page-head-ul .profile-page-head-avatar .img-sec img.bg-img-02 {
  border-radius: 50%;
  width: 105px;
  height: 105px;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-page-head-ul .profile-page-head-avatar .img-sec span {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 50%;
  box-shadow: 0px 0px 6px #00000017;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.profile-page-head-ul .profile-page-head-avatar .img-sec span img {
  width: 50px;
  cursor: pointer;
}

.profile-page-head-content-inner {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.profile-page-head-content-inner li.profile-page-info {
  padding-left: 0px;
  border-left: 0px;
  text-align: left;
}

[dir='rtl'] .profile-page-head-content-inner li.profile-page-info {
  padding-right: 0;
  padding-left: 35px;
}

.profile-page-head-content-inner li {
  color: var(--primary-black);
  padding: 0px 35px;
  text-align: center;
  cursor: pointer;
}

.profile-page-head-content-inner li button {
  background-color: transparent;
  color: #333;
  border: none;
  font-size: 16px;
  font-weight: 400;
}

.profile-page-head-content-inner li:first-child a {
  font-size: 12px;
  margin-top: 8px;
  border: 1px solid var(--theme-color);
  background: var(--theme-color);
  color: var(--white);
  border-radius: 50px;
  display: inline-block;
  padding: 3px 18px 3px 18px;
  color: var(--white);
  text-decoration: none !important;
}

.profile-page-head-content-inner li.active span,
.profile-page-head-content-inner li.active h5 {
  color: var(--theme-color);
  cursor: pointer;
}

.userVideoData h3 {
  text-align: center;
}

.profile-page-head-content-inner li span {
  font-size: 14px;
}

#tabs-nav {
  font-size: var(--font-sz-lg);
  font-weight: var(--font-wt-semibold);
  line-height: 25px;
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #d4e3ed;
  transition: all 0.3s ease;
}

#tabs-nav li {
  display: inline-block;
}

#tabs-nav li a {
  font-size: var(--font-sz-lg);
  font-weight: var(--font-wt-semibold);
  display: inline-block;
  padding-bottom: 6px;
}

#tabs-nav li.active a {
  color: var(--theme-color);
  position: relative;
}

#tabs-nav li.active a:before {
  content: '';
  position: absolute;
  bottom: -2px;
  width: 100%;
  height: 2px;
  left: 50%;
  transform: translate(-50%);
  background: var(--theme-color);
  transition: all 0.3s ease;
}

.profile-page-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-page-header .profile-page-header-icon {
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.profile-page-header .profile-page-header-icon img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-page-header-dropdown {
  position: relative;
  display: inline-block;
}

.profile-page-header-dropdown .drop-menu {
  border-radius: 12px;
  position: absolute;
  box-shadow: 0px 3px 20px #00000012;
  border: 0.5px solid #d4e3ed;
  z-index: 50;
  transform-origin: center 0;
  transform: translateY(50px);
  opacity: 0;
  pointer-events: none;
  will-change: transform, opacity;
  transition: transform 0.25s ease-in-out 0.1s, opacity 0.25s ease-in-out 0.1s;
  background: var(--white);
  right: 0;
  width: 220px;
  top: 114%;
  visibility: hidden;
  text-align: left;
}

.profile-page-header-dropdown .drop-menu:before,
.profile-page-header-dropdown .drop-menu:after {
  content: '';
  display: block;
  position: absolute;
  right: 20px;
  top: -15px;
  width: 0;
  height: 0;
  border-bottom: 8px solid var(--white);
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.profile-page-header-dropdown .drop-menu:before {
  z-index: 2;
}

[dir='rtl'] .profile-page-header-dropdown .drop-menu:before,
[dir='rtl'] .profile-page-header-dropdown .drop-menu:after {
  right: unset;
  left: 20px;
}

.profile-page-header-dropdown .drop-menu:after {
  border-bottom: 9px solid #d4e3ed;
  border-top: 9px solid transparent;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  z-index: 1;
  top: -18px;
  right: 19px;
}

.profile-page-header-dropdown .drop-menu li a {
  display: block;
  width: 100%;
  padding: 16px;
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-medium);
  line-height: 21px;
  color: var(--primary-gray);
  border-bottom: 1px solid var(--secondary-gray);
}

.profile-page-header-dropdown .drop-menu li:last-child a {
  border-bottom: 0;
}

.profile-page-header-dropdown:hover .drop-menu {
  visibility: visible;
  transform: translateY(-6px);
  opacity: 1;
  pointer-events: auto;
  background: var(--white);
}

.profile-page-header-dropdown .drop-menu li a:hover {
  color: var(--theme-color);
}

.profile-box .img-box {
  width: 100px !important;
  height: 100px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid var(--theme-color);
  margin: auto;
  overflow: hidden;
}

.profile-box .img-box img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
}

.profile-box .box h5 {
  font-size: 15px;
  color: var(--black);
  margin-bottom: 5px;
}

.profile-box .box h6 {
  font-size: 13px;
  color: var(--black);
  margin-top: 0px;
}

.profile-box .box a {
  text-decoration: none !important;
}

.profile-videos {
  margin-top: 40px;
}

.profile-videos .userVideoData {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
}

.profile-videos .userVideoData .box {
  position: relative;
}

.repost {
  display: none !important;
  position: absolute;
  top: 0;
  left: 6px;
  bottom: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  grid-gap: 6px;
  gap: 6px;
  right: 6px;
  padding: 10px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.55) 100%);
  border-radius: 8px;
}

.repost-count {
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-medium);
  line-height: 20px;
  color: var(--white);
}

.profile-videos .box .img-box {
  height: 300px !important;
}

.full-sec .right-sec .profile-full-box .profile-page-head .profile-page-head-ul {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}

.profile-page-head-avatar {
  flex: 0 0 80px;
}

@media screen and (min-width: 1600px) {
  .profile-page-head-avatar {
    flex: 0 0 105px;
  }
}

.profile-full-box .profile-page-head-content {
  flex: 1;
}

.profile-full-box .profile-page-head-content #displayName {
  margin-bottom: 6px;
  white-space: nowrap;
}

.profile-full-box .profile-page-head-content #displayName,
.profile-full-box .profile-page-head-content-inner span {
  font-size: var(--font-sz-md);
  color: var(--primary-black);
}

.profile-full-box .profile-page-head-content #userId,
.profile-full-box .profile-page-head-content-inner button,
.profile-full-box .profile-page-head-content #userDescription {
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-medium);
  color: var(--primary-black);
}

.profile-full-box .profile-page-head-content-inner li {
  position: relative;
}

.profile-full-box .profile-page-head-content-inner li::after {
  content: '';
  width: 1px;
  height: 18px;
  background-color: var(--secondary-gray);
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

[dir='rtl'] .profile-full-box .profile-page-head-content-inner li::after {
  right: unset;
  left: 0;
}

.profile-full-box .profile-page-head-content-inner li:last-child::after {
  display: none;
}

.profile-full-box .profile-page-head-content #userDescription {
  margin-top: 20px;
}

[dir='rtl'] .profile-full-box .profile-page-head-content #userDescription {
  text-align: right;
}

.profile-page-list {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .profile-videos .userVideoData {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .full-sec .right-sec .profile-full-box .profile-page-head .profile-page-head-ul {
    gap: 20px;
  }
  .profile-full-box .profile-page-head-content-inner li.profile-page-info {
    padding-left: 0;
  }

  .profile-videos .userVideoData {
    grid-template-columns: repeat(5, 1fr);
  }
  [dir='rtl'] .profile-page-head-content-inner li.profile-page-info {
    padding-left: 35px;
  }
  [dir='rtl'] .profile-page-head-content-inner li.profile-page-info h4,
  [dir='rtl'] .profile-page-head-content-inner li.profile-page-info h5 {
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .profile-full-box .profile-page-head {
    padding: 15px;
  }
  .tabs-section {
    padding: 15px;
  }
  #tabs-nav {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .full-sec .right-sec .profile-full-box .profile-page-head .profile-page-head-ul {
    flex-direction: column;
    gap: 10px;
  }
  .profile-page-head-ul .profile-page-head-avatar {
    width: 100%;
  }
  .profile-page-head-ul .profile-page-head-avatar .img-sec {
    margin: auto;
  }
  .full-sec .right-sec .full-box.profile-full-box {
    padding-top: 0;
  }
  .profile-full-box .profile-page-head-content {
    width: 100%;
  }
  .profile-full-box .profile-page-head-content .profile-page-head-content-inner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .profile-full-box .profile-page-head-content-inner li.profile-page-info {
    padding: 0;
    margin-bottom: 10px;
    text-align: center;
  }
  .profile-full-box .profile-page-head-content-inner li.profile-page-info::after {
    display: none;
  }
  .profile-full-box .profile-page-head-content-inner .profile-page-list li {
    padding: 0 24px;
    margin-bottom: 0;
    flex: 1;
  }

  .profile-full-box .profile-videos {
    margin-left: 12px !important;
    margin-top: 15px;
    padding-right: 12px;
  }

  .profile-page-head .profile-page-header {
    display: none;
  }

  #tabs-nav li a {
    font-size: var(--font-sz-sm);
    font-weight: var(--font-wt-semibold);
  }

  .profile-videos .userVideoData {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .profile-full-box .profile-page-head-content #userDescription {
    text-align: left;
  }

  .profile-full-box .profile-page-head-content #displayName,
  .profile-full-box .profile-page-head-content-inner span {
    font-size: var(--font-sz-sm);
  }

  .profile-full-box .profile-page-head-content #userId,
  .profile-full-box .profile-page-head-content-inner button,
  .profile-full-box .profile-page-head-content #userDescription {
    font-size: var(--font-sz-xs);
  }
}
