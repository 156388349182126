/* @import url(../../assets/css/Variables.css);

.accordion-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-header {
  margin: 0;
}

.accordion-button {
  width: 100%;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.accordion-button .downArrow {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.accordion-button.collapsed .downArrow {
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid rgba(212, 227, 237, 0.5);
}

.toggle-container .toggle-title,
.post-title {
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-semibold);
  line-height: 24px;
  color: var(--primary-black);
  margin: 0;
}

.post-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  margin-bottom: 0;
}

[dir="rtl"] .toggle-switch {
  transform: rotate(180deg);
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: var(--white);
  box-shadow: 0px 0px 6px #00000029;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--theme-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 50px;
}

.slider.round:before {
  border-radius: 50%;
}

.btn_sound {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-semibold);
  line-height: 25px;
  color: var(--theme-color);
}

.panel-list {
  display: block;
}

.panel-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.sound {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.sound-image {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}

.sound-image img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.sound-title {
  font-size: var(--font-sz-md);
  font-weight: var(--font-wt-semibold);
  line-height: 24px;
  color: var(--primary-black);
  margin: 0;
}

.sound-artist {
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-semibold);
  line-height: 24px;
  color: var(--primary-gray);
  margin: 0;
}

.sound-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.sound-actions-btn {
  width: 34px;
  height: 34px;
}

@media screen and (max-width: 991px) {
  .side-fixed-panel-inner.sound-panel .full-box {
    padding-top: 0;
  }
  .side-fixed-panel-inner.sound-panel .page-search-box {
    padding-left: 0;
    padding-right: 0;
  }
} */
