@import url(../../assets/css/Variables.css);

.leaderboard-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.leaderboard-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0 20px;
}

.leaderboard-list-item:nth-child(4) {
  border-top: 1px solid #454547;
  padding-top: 32px;
}

.leaderboard-user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  flex: 0 0 50%;
}

.leaderboard-user.currentUser {
  border-radius: 27px 0px 0px 27px;
  opacity: 0.8;
  background: linear-gradient(90deg, rgba(154, 83, 218, 0.6) 0%, rgba(154, 83, 218, 0) 101.16%);
  padding: 4px 0 4px 8px;
  margin-left: -8px;
}

.leaderboard-user-rank {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #232225;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #b9b9ba;
  margin: 5px 16px 5px 5px;
}

.leaderboard-user img {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--borders-regular);
}

.leaderboard-user-place {
  margin-right: 8px;
}
.leaderboard-user-place img {
  flex: none;
  height: 30px;
  width: auto;
  object-fit: contain;
  border: 0;
  border-radius: 0;
}

.leaderboard-user h4 {
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-regular);
  line-height: 20px;
  color: var(--secondary-black);
}

.leaderboard-thumbnail img {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  border-radius: 4px;
  border: 1px solid var(--White-White-05);
  object-fit: cover;
}
