@tailwind base;
@tailwind components;
@tailwind utilities;
@import './app//assets/css/animate.min.scss';

// List of breakpoints that uses responsive font sizes
$responsiveFontSizeBreakpoints: 1440px, 2560px, 3840px, 7680px;

html {
  @for $i from 1 through length($responsiveFontSizeBreakpoints) {
    @if $i > 1 {
      @media all and (min-width: nth($responsiveFontSizeBreakpoints, $i)) {
        font-size: calc(nth($responsiveFontSizeBreakpoints, $i) / nth($responsiveFontSizeBreakpoints, 1)) * 100%;
      }
    }
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-Bold';
    src: url('../../../libs/assets/src/fonts/STCForward-Bold.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-BoldItalic';
    src: url('../../../libs/assets/src/fonts/STCForward-BoldItalic.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-ExtraBold';
    src: url('../../../libs/assets/src/fonts/STCForward-ExtraBold.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-ExtraBoldItalic';
    src: url('../../../libs/assets/src/fonts/STCForward-ExtraBoldItalic.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-Italic';
    src: url('../../../libs/assets/src/fonts/STCForward-Italic.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-Light';
    src: url('../../../libs/assets/src/fonts/STCForward-Light.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-LightItalic';
    src: url('../../../libs/assets/src/fonts/STCForward-LightItalic.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-Medium';
    src: url('../../../libs/assets/src/fonts/STCForward-Medium.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-MediumItalic';
    src: url('../../../libs/assets/src/fonts/STCForward-MediumItalic.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-Regular';
    src: url('../../../libs/assets/src/fonts/STCForward-Regular.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-Thin';
    src: url('../../../libs/assets/src/fonts/STCForward-Thin.ttf');
  }
}

@layer base {
  @font-face {
    font-family: 'STCForward-ThinItalic';
    src: url('../../../libs/assets/src/fonts/STCForward-ThinItalic.ttf');
  }
}

@layer components {
  .responsive-text-caption {
    @apply text-caption 2K:text-[21.33px]/[28.44px] 4K:text-[32px]/[42.66px] 8K:text-[64px]/[85.33px];
  }

  .responsive-text-bodySmall {
    @apply text-bodySmall 2K:text-[24.88px]/[35.55px] 4K:text-[37.33px]/[53.33px] 8K:text-[74.66px]/[106.66px];
  }

  .responsive-text-body {
    @apply text-body 2K:text-[28.44px]/[42.66px] 4K:text-[42.66px]/[64px] 8K:text-[85.33px]/[128px];
  }

  .responsive-text-bodyLarge {
    @apply text-bodyLarge 2K:text-[32px]/[42.66px] 4K:text-[48px]/[64px] 8K:text-[96px]/[128px];
  }

  .responsive-text-subtitle {
    @apply text-subtitle 2K:text-[42.66px]/[56.88px] 4K:text-[64px]/[85.33px] 8K:text-[128px]/[170.66px];
  }

  .responsive-text-title {
    @apply text-title 2K:text-[56.88px]/[64px] 4K:text-[85.33px]/[96px] 8K:text-[170.66px]/[192px];
  }

  .responsive-text-title {
    @apply text-title 2K:text-[56.88px]/[64px] 4K:text-[85.33px]/[96px] 8K:text-[170.66px]/[192px];
  }

  .responsive-text-bigTitle {
    @apply text-bigTitle 2K:text-[71.11px]/[85.33px] 4K:text-[106.66px]/[128px] 8K:text-[213.33px]/[256px];
  }

  .responsive-text-huge {
    @apply text-huge 2K:text-[113.77px]/[120.88px] 4K:text-[170.66px]/[181.33px] 8K:text-[341.33px]/[362.66px];
  }
}

/* You can add global styles to this file, and also import other style files */
html,
body,
:root {
  height: 100vh;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

.sr-only {
  /* all screens */
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.break-phrase {
  word-break: auto-phrase;
}

.underline-ltr-animation {
  position: relative;
}

.underline-ltr-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  left: 0;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.underline-ltr-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.underline-rtl-animation {
  position: relative;
}

.underline-rtl-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  left: 0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.underline-rtl-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom right;
}

.button-animation-ltr {
  display: inline-block;
  transition: all 0.6s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    transition: all 0.6s;
    z-index: -1;
  }
  &:hover {
    &:before {
      width: 100%;
    }
  }
}

.button-animation-rtl {
  display: inline-block;
  transition: all 0.6s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.6s;
    z-index: -1;
  }
  &:hover {
    &:before {
      width: 0%;
    }
  }
  &::after {
    width: 100%;
  }
}

@keyframes float-1rem {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-1rem);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float-13rem {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-13rem);
  }
  100% {
    transform: translatey(0px);
  }
}

.floating-animation-1 {
  overflow: hidden;
  transform: translatey(0px);
  animation: float-1rem 6s ease-in-out infinite;
}
.floating-animation-2 {
  overflow: hidden;
  transform: translatey(0px);
  animation: float-13rem 9s ease-in-out infinite;
}

.floating-animation-3 {
  overflow: hidden;
  transform: translatey(0px);
  animation: float-1rem 4s ease-in-out infinite;
}

@media (max-width: 640px) {
  @keyframes float-13rem {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-5rem);
    }
    100% {
      transform: translatey(0px);
    }
  }
}

.Typewriter {
  display: inline-block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-text {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}
.fade-in-text2 {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

input[value='']:not(:focus).hideDate {
  color: transparent;
}

.dateTimeHide {
  -webkit-text-fill-color: transparent;
}

.dateTimeShowLight {
  -webkit-text-fill-color: #ffffff;
}

.dateTimeShowDark {
  -webkit-text-fill-color: #171619;
}

.Toastify__toast--success {
  background: linear-gradient(
      0deg,
      var(--colour-feedback-green-10, rgba(16, 135, 21, 0.1)) 0%,
      var(--colour-feedback-green-10, rgba(16, 135, 21, 0.1)) 100%
    ),
    var(--surface-default, #fff);
}

.webkit-truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #cfe7d0 !important;
  box-shadow: none !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #f9d6d6 !important;
  box-shadow: none !important;
}

.Toastify__toast-container {
  width: auto !important;
  margin-top: 4.31rem !important;
}

.Toastify__toast-theme--colored.Toastify__toast--default,
.Toastify__toast-theme--light {
  background-color: transparent !important;
  box-shadow: none !important;
}

@media (max-width: 425px) {
  .Toastify__toast-container {
    width: 100% !important;
  }
}

.medium-banner-gradient {
  background: radial-gradient(44.55% 44.55% at 50% 50%, rgba(255, 106, 57, 0.8) 0%, rgba(255, 106, 57, 0) 100%);
}

@mixin rotate($degrees) {
  transform: rotate($degrees);
}

.custom-slider {
  writing-mode: vertical-rl;
  -webkit-appearance: slider-vertical; /* For Webkit browsers */
  appearance: slider-vertical; /* Standard property */
  @include rotate(180deg);
}

.rotate-180deg {
  @include rotate(180deg);
}

.rotate-0deg {
  @include rotate(0deg);
}

/* Global Styles or in your Tailwind CSS config */
.seekbar {
  -webkit-appearance: none;
  appearance: none;
  height: 0.5rem;
  border-radius: 0.75rem;
  outline: none;
}

.seekbar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #fc5a03;
  cursor: pointer;
}

.seekbar::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #fc5a03;
  cursor: pointer;
}

.seekbar::-ms-thumb {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #fc5a03;
  cursor: pointer;
}

.carousel.carousel-slider {
  height: 100%;
}
