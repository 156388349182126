@import url('../../../assets/css/Variables.css');

.step_header {
  margin-top: 24px;
}

.step_lists {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;
  padding: 36px;
}

.step_lists_item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.step_info h4 {
  color: var(--secondary-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.step_info p {
  color: var(--White-White-50);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 4px 0 0 0;
}

.step_wrapper [data-active='false'] {
  border-color: #454547;
  color: #454547;
}

.step_wrapper [data-active='false'] h4,
.step_wrapper [data-active='true'] h4 span {
  color: #454547;
}

.step_wrapper [data-active='true'] {
  border-color: var(--theme-color);
}

.step_wrapper [data-active='true'] h4,
.step_wrapper [data-active='true'] h4 span {
  color: var(--theme-color);
}

@media screen and (max-width: 991px) {
  .step_lists {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px 0 0;
    gap: 20px;
  }
}
