:root {
  --player-offset: 85px;
  --max-width: 1920px;
  --border-radius: 16px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace',
    'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --theme-color: #e95f2a;
  --theme-color-light: #f8cfbf;
  --theme-white: #ffffff;

  --white: #ffffff;
  --black: #000000;

  --primary-black: #242424;
  --secondary-black: #171619;
  --theme-secondary-black: #171619;

  --primary-red: #ff0000;
  --box-shadow-light: #00000014;

  --primary-gray: #596970;
  --secondary-gray: #adbacf;

  --theme-default-color: #596970;
  --borders-regular: #454547;

  --White-White-70: rgba(0, 0, 0, 0.7);
  --White-White-50: rgba(0, 0, 0, 0.5);
  --White-White-20: rgba(0, 0, 0, 0.2);
  --White-White-10: rgba(0, 0, 0, 0.1);
  --White-White-05: rgba(0, 0, 0, 0.05);

  --Black-Black-70: rgba(23, 22, 25, 0.7);

  --text-tertiary: #8b8a8c;

  --borders-regular: #454547;

  --font-sz-xxs: 12px;
  --font-sz-xs: 14px;
  --font-sz-sm: 16px;
  --font-sz-md: 18px;
  --font-sz-lg: 20px;

  --font-wt-regular: 400;
  --font-wt-medium: 500;
  --font-wt-semibold: 600;
  --font-wt-bold: 700;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
