@import url('../../../../assets/css/Variables.css');

.back-btn-a {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: none;
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-regular);
}

.back-btn-a div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: 600;
}

.mainpage-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--White-White-10);
}

.mainpage-head .profile-head-back {
  margin: 12px 0;
}

.mainpage-head .sound-image {
  width: 105px;
  height: 105px;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-bottom: 15px;
}

.mainpage-head .sound-image img {
  border-radius: 50%;
}

.mainpage-head .sound-title,
.mainpage-head .sound-artist {
  font-weight: var(--font-wt-medium);
}

audio {
  width: 100%;
  max-width: 500px;
}

audio::-webkit-media-controls-panel {
  color: var(--primary-black);
}

.mainpage-body {
  margin-top: 2rem;
  text-align: center;
  font-size: 20px;
}

.sound-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
}

.sound-grid-item {
  width: 100%;
  height: 300px;
  border-radius: 8px;
}

.sound-grid-item img,
.sound-grid-item video {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -o-object-fit: cover;
  object-fit: cover;
}

@media screen and (max-width: 1600px) {
  .sound-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 1400px) {
  .sound-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 991px) {
  .mainpage {
    padding: 16px;
  }
}

@media screen and (max-width: 576px) {
  .sound-grid {
    gap: 5px;
  }

  .sound-grid-item {
    height: 145px;
  }
}
