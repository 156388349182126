@import url(../../assets/css/Variables.css);

.leaderboard-btn {
  padding: 8px 24px;
  border-radius: 2px;
  border: 1px solid var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--theme-color);
}

.join-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.join-btn:hover svg path {
  fill: var(--theme-color);
}
