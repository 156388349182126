@import url(./Variables.css);

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: STCForward-Regular;
}

html,
body {
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
  color: var(--primary-black);
  padding: 0 !important;
  background-color: var(--white);
}

a {
  color: inherit;
  text-decoration: none !important;
}

/* Scroll Bar Style Start */
/* Width */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-gray);
  border-radius: 6px;
}
/* Scroll Bar Style End */

/* Padding Class  */
.p_0 {
  padding: 0 !important;
}

.pr_0 {
  padding-right: 0 !important;
}

.pr_1 {
  padding-right: 10px !important;
}

.pr_2 {
  padding-right: 20px !important;
}

.pr_3 {
  padding-right: 30px !important;
}

.pr_4 {
  padding-right: 40px !important;
}

.pr_5 {
  padding-right: 50px !important;
}

.pr_6 {
  padding-right: 60px !important;
}

/* Margin Class */
.mb_0 {
  margin-bottom: 0 !important;
}

.bg-active {
  background-color: var(--theme-color);
}

.btn_transparent {
  border: 0;
  background-color: transparent;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_primary {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin: auto;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background: var(--theme-color);
  border: 1px solid var(--theme-color);
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-medium);
  color: var(--theme-white);
  transition: all 0.3s ease;
}

.btn_primary:hover {
  background: var(--white);
  color: var(--theme-color);
  transition: all 0.3s ease;
}

.btn_primary svg path {
  fill: var(--white);
  transition: all 0.3s ease;
}

.btn_primary:hover svg path {
  fill: var(--theme-color);
  transition: all 0.3s ease;
}

.custom-dot {
  margin-top: -8px;
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-bold);
  line-height: 24px;
  color: var(--text-tertiary);
}

@media screen and (max-width: 576px) {
  .custom-dot {
    margin-top: -7px;
  }
}

.back-btn-a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--theme-default-color);
}

.back-btn-a svg {
  margin-top: -2px;
}

.back-btn-a svg path {
  fill: var(--theme-default-color) !important;
}

.back-header {
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  margin-top: 16px;
}

.back-header h2 {
  color: var(--secondary-black);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.24px;
  margin: 0;
}

.back-header a {
  color: var(--text-secondary, #b9b9ba);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.back-header ul {
  width: 100%;
  text-align: center;
  position: relative;
}

.back-header .back-btn-a {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: var(--font-sz-md);
  font-weight: var(--font-wt-medium);
}

.av-icon {
  display: none;
}

.no-result {
  text-align: center;
  margin: 10px 0;
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-regular);
}
