.cmp-footer {
  .qr-code {
    background: linear-gradient(to right, white 0.25rem, transparent 0.25rem) 0 0,
      linear-gradient(to right, white 0.25rem, transparent 0.25rem) 0 100%,
      linear-gradient(to left, white 0.25rem, transparent 0.25rem) 100% 0,
      linear-gradient(to left, white 0.25rem, transparent 0.25rem) 100% 100%,
      linear-gradient(to bottom, white 0.25rem, transparent 0.25rem) 0 0,
      linear-gradient(to bottom, white 0.25rem, transparent 0.25rem) 100% 0,
      linear-gradient(to top, white 0.25rem, transparent 0.25rem) 0 100%,
      linear-gradient(to top, white 0.25rem, transparent 0.25rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 0.625rem 0.625rem;
  }
}
