@import url(../../../assets/css/Variables.css);

.accordion-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-header {
  margin: 0;
}

.accordion-button {
  width: 100%;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.accordion-button .downArrow {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.accordion-button.collapsed .downArrow {
  transform: rotate(0deg);
  transition: all 0.3s ease;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  margin-bottom: 1.5rem;
}
.toggle-container:last-child {
  margin-bottom: unset;
}

.toggle-container .toggle-title,
.post-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--White-White-70);
}

.post-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  margin-bottom: 0;
}

[dir='rtl'] .toggle-switch {
  transform: rotate(180deg);
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c4c4c4;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  box-shadow: 0px 0px 6px #00000029;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--theme-color);
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.slider.round {
  border-radius: 50px;
}

.slider.round:before {
  border-radius: 50%;
}

.btn_sound {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin-top: 20px;
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-semibold);
  line-height: 25px;
  color: var(--theme-color);
}

.panel-list {
  display: block;
  margin-top: 20px;
}

.panel-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.sound {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.sound-info {
  flex: 1;
}

.sound-image {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.sound-image img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
}

.sound-title {
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-regular);
  line-height: 20px;
  color: var(--secondary-black);
  margin: 0;
  word-wrap: break-word;
}

@media screen and (max-width: 991px) {
  .sound-title {
    width: auto;
  }
}

.sound-artist {
  font-size: var(--font-sz-xxs);
  font-weight: var(--font-wt-regular);
  line-height: 18px;
  color: var(--primary-gray);
  margin: 0;
}

.sound-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.sound-actions-btn {
  width: 34px;
  height: 34px;
}

.creat-full.uploadInputWrapper .creat-box {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%235D5C5EFF' stroke-width='2' stroke-dasharray='8%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  min-height: 450px;
}

.uploadInputWrapper .videoControls {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.videoControlRow,
.videoControl_playpause,
.videoControl_muteunmute {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.videoControl_muteunmute svg {
  width: 20px;
  height: 20px;
}

.videoControl_playpause button {
  width: 32px;
  height: 32px;
  background-color: #232225;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.videoControl_playpause .videoTime {
  color: var(--secondary-black);
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-regular);
  line-height: 1;
}

.progress-bar {
  height: 5px;
  border-radius: 4px;
  margin: 0 2px;
  background: #232225;
  cursor: pointer;
  position: relative;
}

.progress {
  height: 100%;
  border-radius: 4px;
  background: #ff6a39;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: linear;
}

.progress-handle {
  position: absolute;
  top: 50%;
  right: 0;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 50%;
  transform: translate(50%, -50%);
  pointer-events: none; /* Prevent the handle from capturing clicks */
}

.creat-full.uploadInputWrapper .creat-box-02 {
  padding-bottom: 100px;
}

@media screen and (max-width: 991px) {
  .side-fixed-panel-inner.sound-panel .full-box {
    padding-top: 0;
  }
  .side-fixed-panel-inner.sound-panel .page-search-box {
    padding-left: 0;
    padding-right: 0;
  }

  .creat-box-02 {
    grid-template-columns: 1fr;
  }
}
