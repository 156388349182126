@import url(../../../assets/css/Variables.css);

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 49;
  overflow-y: auto;
  transition: all 0.3s ease;
}

.modal {
  width: 100%;
  height: 100%;
  background: transparent;
  position: relative;
}

.qp-modal-content {
  background: var(--white);
  padding: 20px;
  transition: all 0.3s ease;
  transform: translateY(0) translateX(-50%);
  position: absolute;
  z-index: 49;
  left: 50%;
  top: 0;
  opacity: 0;
  width: calc((100dvh - var(--player-offset)) * 0.5625);
  box-shadow: 0px 0px 32px #00000014;
  border-radius: 6px;
  overflow-y: auto;
}

.modal.open.slidedown .qp-modal-content {
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 1;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 49;
}

.modal-title {
  font-size: var(--font-sz-md);
  font-weight: var(--font-wt-bold);
  line-height: 26px;
  color: var(--primary-black);
  padding: 0 35px;
  text-align: center;
  margin-bottom: 10px;
}

.modal-subtitle {
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-medium);
  line-height: 20px;
  color: var(--primary-gray);
  text-align: center;
  margin-bottom: 10px;
}

.modal-body {
  text-align: center;
}

.modal-body .error-img {
  width: 50px;
  margin: 0 auto 20px auto;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
  border-radius: 50%;
  padding: 1px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

[dir='rtl'] .report-modal .close-button {
  right: unset;
  left: 10px;
}

[dir='rtl'] .close-button {
  right: unset;
  left: 0;
}

.radio-inputfield {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  cursor: pointer;
}
.higher-z {
  z-index: 999 !important;
}
.radio-inputfield label {
  cursor: pointer;
  text-align: start;
}

.comment-report-modal {
  width: 100%;
}
.comment-report-modal .radio-inputfield {
  width: 100%;
}
.comment-report-modal + .modal-overlay {
  border-radius: 0 16px 16px 0;
}

.modal.follow-modal {
  margin-left: 0;
}

.modal.follow-modal .qp-modal-content {
  max-height: 60dvh;
}

.auth-modal .qp-modal-content {
  overflow: visible;
  width: 100%;
  max-width: 590px !important;
  padding: 2rem !important;
  background-color: #4f008c;
}

.auth-modal .modal-body p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 0px;
  margin-top: -8px;
}

.auth-modal .modal-title {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  color: #ffffff;
  padding: 0;
  margin: 0;
}
.auth-modal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.auth-modal .modal-body .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 0;
}
.auth-modal .qp-modal-content {
  border-radius: 0;
}
.auth-modal .close-button circle {
  display: none;
}
.auth-modal .modal-body .button-group .close-btn,
.auth-modal .modal-body .button-group .signIn-btn {
  min-width: 100px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
}

.auth-modal .modal-body .button-group .close-btn {
  border-color: #fff;
}

.auth-modal .modal-body .button-group .signIn-btn {
  background-color: #e95f2a;
  border-color: #e95f2a;
}

.auth-modal .close-button {
  top: 0;
  right: 0;
  background: transparent;
}

[dir='rtl'] .auth-modal .close-button {
  right: unset;
}

.radio-inputfield {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  cursor: pointer;
  padding: 14px 0;
  border-bottom: 1px solid #2e2d30;
}

.radio-inputfield label {
  cursor: pointer;
  color: var((--secondary-black));
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding-right: 0;
  padding-left: 32px;
  flex: 1;
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.radio-inputfield .radio-container input[type='radio'] {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.radio-inputfield .checkmark {
  left: 0;
  right: unset;
}

.modal.report-modal .qp-modal-content {
  padding: 0 48px;
  border-radius: 8px;
  border: 1px solid var(--borders-regular);
  background: var(--white);
  width: 100%;
  max-width: 430px;
  max-height: 75dvh;
}

.modal.report-modal .modal-title {
  color: var(--secondary-black);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--white);
  padding-top: 48px;
  padding-left: 0;
}

[dir='rtl'] .modal.report-modal .modal-title {
  text-align: right;
  padding-right: 0;
}

.modal.report-modal .modal-subtitle {
  color: var(--primary-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  position: sticky;
  padding: 4px 0;
  top: 80px;
  z-index: 1;
  background: var(--white);
}

[dir='rtl'] .modal.report-modal .modal-subtitle {
  text-align: right;
}

.modal.report-modal .bottom-button-group {
  position: sticky;
  bottom: 0;
  background: var(--white);
  z-index: 1;
  padding-bottom: 48px;
}

.modal.report-modal .close-button {
  top: 48px;
}

@media screen and (max-height: 768px) {
  .modal-overlay {
    align-items: flex-start;
  }
  .modal .qp-modal-content {
    width: 100%;
    max-width: 475px;
  }
}

@media screen and (max-width: 991px) {
  .modal.open.slidedown.report-modal .qp-modal-content {
    padding: 0 20px !important;
  }
  .modal.report-modal .bottom-button-group {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 576px) {
  .modal {
    margin-left: 0;
  }
  .modal .qp-modal-content {
    top: unset;
    bottom: -100%;
    width: 100%;
    border-radius: 8px 8px 0 0;
    transform: unset;
    left: unset;
    margin-left: 0;
  }
  .modal.open.slidedown .qp-modal-content {
    top: unset;
    transform: unset;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    padding-bottom: 60px !important;
  }

  .modal.open.slidedown.auth-modal {
    padding-bottom: 0 !important;
  }

  .auth-modal.modal.open.slidedown .qp-modal-content {
    background-color: #4f008d;
    padding: 20px 24px !important;
  }

  .auth-modal .qp-modal-content .modal-body {
    gap: 20px;
  }

  .auth-modal .qp-modal-content .modal-body .auth-image {
    position: static;
    transform: none;
    margin: auto;
  }

  .auth-modal .modal-title {
    font-size: 24px;
    color: #ffffff;
    font-weight: 500;
    line-height: 35px;
  }

  .auth-modal .modal-body p {
    color: #ffffff;
    font-size: 16px;
    margin-top: 0;
  }

  .auth-modal .modal-body p br {
    display: none;
  }

  .auth-modal .modal-body .button-group {
    flex-direction: row;
  }

  .auth-modal .modal-body .button-group .signIn-btn {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    padding: 10px 20px;
  }

  .auth-modal .modal-body .button-group .close-btn {
    background-color: transparent;
    border-color: #ffffff;
    padding: 10px 20px;
    color: #ffffff;
  }

  .auth-modal .modal-body .button-group .close-btn,
  .auth-modal .modal-body .button-group .signIn-btn {
    padding: 4px 24px;
  }
}
