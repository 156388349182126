.custom-scroll::-webkit-scrollbar {
  display: block;
  width: 0.25rem;
}
.custom-scroll::-webkit-scrollbar-track {
  background: #2e2d30;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #e95f2a;
  border-radius: 0.125rem;
}
