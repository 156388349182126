@import url(./Variables.css);
:root {
  --default-h: 32px;
  --default-viewport-value: 120px;
  --default-screen-h: calc(100dvh - var(--default-viewport-value));
}

* {
  font-family: STCForward-Regular;
}

.main-section {
  margin-bottom: 50px;
}
.main-wrapper .main-head {
  width: 100%;
  padding: 12px 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  border-bottom: 1px solid var(--White-White-10);
  background: var(--white);
}

.main-head-title {
  background: rgba(154, 83, 218, 0.12);
  border-radius: 2px;
  padding: 7px 8px 4px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #9a53da;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 90px;
}

@media screen and (max-width: 991px) {
  .main-wrapper .main-head {
    padding: 12px 20px;
    overflow-x: auto;
  }
}

.main-wrapper .main-head .categoryLabelsList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.main-wrapper .main-head .categoryLabelsList li .categoryLabelsList__button {
  display: flex;
  height: 22px;
  padding: 3px var(--Spacing-Elements-2x, 8px);
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 2px;
  background: var(--White-White-05);
  color: var(--White-White-70);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
}

.main-wrapper .main-head .categoryLabelsList li .categoryLabelsList__button.active {
  background-color: var(--theme-color);
  color: var(--theme-white);
  pointer-events: none;
}

.main-create {
  width: calc(100% - 300px);
  margin-left: 300px;
  padding: 16px 32px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

@media screen and (min-width: 1600px) {
  .main-create {
    width: calc(100% - 350px);
    margin-left: 350px;
  }
}

.main-create-menu {
  width: calc((var(--default-screen-h) - var(--default-h)) * 0.5625);
  border-radius: var(--Spacing-Elements-1x, 4px);
  border: 1px solid var(--borders-regular, #454547);
}

.main-create .contextMenu {
  min-width: 220px;
  padding: 8px 0px;
  border-radius: 0 0 8px 8px;
  background-color: #171619;
  border: 1px solid #454547;
  top: 100%;
  z-index: 99;
}

.main-create .contextMenu .contextMenuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: var(--text-black, #fff);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 16px;
}

.main-create .main-create-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  padding: 10px 8px;
  position: relative;
}

.main-create .main-create-content .create-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #e95f2a;
}

.main-create .main-create-content .create-btn svg {
  margin-top: -2px;
}

.main-create .main-create-content .create-btn [role='button'] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.main-create .main-create-content .create-text {
  color: var(--text-secondary, #b9b9ba);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  white-space: nowrap;
}

.section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin: 40px 0 32px 0;
}
.section-head .btn_primary {
  margin: 0;
  color: var(--theme-white);
}
.section-head-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.section-head-title h2 {
  overflow: hidden;
  color: var(--secondary-black);
  text-overflow: ellipsis;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.64px;
}
.section-head-title p {
  color: var(--secondary-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.emptyFeed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emptyFeed-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px 0;
}

.emptyFeed-image {
  max-width: 245px;
  object-fit: contain;
}

.emptyFeed-title {
  color: var(--secondary-black);
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  margin: 32px 0 16px 0;
}

.emptyFeed-description {
  color: var(--text-secondary, #b9b9ba);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.emptyFeed-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 590px;
  width: 100%;
}

.emptyFeed-lists-heading {
  color: var(--secondary-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.emptyFeed-lists {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 240px;
  overflow: auto;
  padding-right: 10px;
}
.emptyFeed-lists-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
}

.emptyFeed-lists-item .userProfile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.emptyFeed-lists-item .userProfile img {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  border-radius: 50%;
  object-fit: cover;
}

.emptyFeed-lists-item .userProfile .userProfile-name {
  display: flex;
  flex-direction: column;
}

.emptyFeed-lists-item .userProfile .userProfile-name h4 {
  color: var(--secondary-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.emptyFeed-lists-item .userProfile .userProfile-name p {
  color: var(--secondary-black);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin: 0;
}

.emptyFeed-lists-item .userProfile-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;
}

.emptyFeed-lists-item .userProfile-row .userProfile-info {
  display: flex;
}

.emptyFeed-lists-item .userProfile-row .userProfile-info-divider {
  width: 1px;
  height: 24px;
  background: var(--borders-regular, #454547);
  margin: 0 12px;
}

.emptyFeed-lists-item .userProfile-row .userProfile-info-item {
  color: var(--secondary-black);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.emptyFeed-lists-item .userProfile-row .userProfile-info-item span {
  color: var(--text-secondary, #b9b9ba);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.emptyFeed-lists-item .userProfile-row .userProfile-btn {
  color: var(--buttons-link-default, #e95f2a);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.emptyFeed-lists-item .userProfile-row .userProfile-btn.userProfile-following {
  color: #b9b9ba;
}

.tagged-sound {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.tagged-sound img {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  border-radius: 2px;
  object-fit: cover;
}

.tagged-sound p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;
  color: var(--secondary-black);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.tagged-sound p .custom-dot {
  color: var(--secondary-black);
}

.repost-icon {
  justify-content: center !important;
  height: 32px;
}

.player_overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.player_overlay .toast-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.player_overlay .toast-container .toast-body {
  color: var(--theme-white);
  text-align: center;
}

.player_wrapper {
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  height: var(--default-screen-h);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
  padding-left: 300px;
}
@media screen and (min-width: 1600px) {
  .player_wrapper {
    padding-left: 350px;
  }
}
.player_viewport {
  --default-screen-h: calc(100dvh - 120px);
}

.next-btn,
.prev-btn {
  position: absolute;
  left: 120px;
  gap: 10px;
  width: fit-content;
  color: var(--White-White-50);
  background-color: var(--white);
  min-width: 150px;
}

.next-btn span,
.prev-btn span {
  color: var(--White-White-50);
  font-size: var(--font-sz-xs);
  font-weight: 400;
  line-height: 20px;
}

.prev-btn {
  bottom: 65px;
}

.next-btn {
  bottom: 20px;
}

.player_item_wrapper {
  display: flex;
  justify-content: center;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  position: relative;
  padding: 16px 32px;
  gap: 10px;
}

.video_expanded {
  height: calc(var(--default-screen-h) - var(--default-h));
  width: calc((var(--default-screen-h) - var(--default-h)) * 0.5625);
  padding: 0 0 0 24px;
  transition: all 0.3s ease;
  z-index: 0;
  position: relative;
  overflow-x: visible;
}

.video_expanded_head {
  padding-bottom: 12px;
  background-color: transparent;
  z-index: 1;
  display: block;
}

.video_expanded_head .profile_thumbnail {
  margin-bottom: 16px;
}

@media screen and (max-width: 991px) {
  .video_expanded_head {
    display: none;
  }
}

.video_expanded_hidden {
  width: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  transform: translateX(-100%);
}

.video_expanded_visible {
  width: calc((var(--default-screen-h) - var(--default-h)) * 0.5625);
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
}

[dir='rtl'] .video_expanded {
  border-radius: 16px 0 0 16px;
}

.video_expanded .user_info {
  position: static;
  padding: 0;
  background: none;
  border-radius: 0;
  display: block;
  min-height: unset;
}

.video_expanded .user_info .top_info,
.video_expanded .user_info .post_desc {
  padding: 0;
}

.video_expanded .user_info .top_info a {
  flex: 1;
}

.video_expanded .user_info .top_info {
  margin-bottom: 24px;
}

.video_expanded .user_info .post_desc {
  padding: 0;
  margin-bottom: 40px;
}

.video_expanded .user_info .post_desc .title,
.video_expanded .user_info .post_desc .desc {
  color: var(--primary-gray);
}
.video_expanded .user_info .post_desc .desc a {
  color: var(--secondary-black);
  padding-right: 8px;
  display: inline-block;
}

.vertical_comment .timeStamp {
  color: var(--White-White-50);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.video_expanded .vertical_comment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8;
}

@media screen and (max-width: 1368px) {
  .video_expanded .vertical_comment > div.flex.gap-16 {
    gap: 10px;
  }
}

.video_expanded .vertical_comment button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.video_expanded .vertical_comment button .icon-count {
  color: var(--primary-gray);
  padding-top: 2px;
  font-size: var(--font-sz-xs);
  font-weight: 400;
  line-height: 20px;
}

.player_item {
  position: relative;
  box-sizing: border-box;
  height: calc(var(--default-screen-h) - var(--default-h));
  width: calc((var(--default-screen-h) - var(--default-h)) * 0.5625);
  display: flex;
  transition: all 0.5s ease;
  z-index: 1;
  border-radius: 4px;
  overflow-x: hidden;
}

.video_player {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
  min-height: unset !important;
  max-height: unset !important;
  transition: border-radius 0.5s ease-in-out;
}

.video_progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 95%;
  margin: 0 auto;
  z-index: 1;
  display: none;
}

.video_progress::-webkit-progress-value {
  background-color: red;
}

.video_controls {
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 12px;
  transition: all 0.2s ease-in;
  border-radius: 0;
  z-index: 10;
}

.video_controls.video_controls_translateX {
  bottom: 90px;
}

@media screen and (max-width: 991px) {
  .video_controls {
    bottom: unset;
    top: 0;
  }
}

.video_views {
  position: absolute;
  top: -20px;
  left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 6px;
  border-radius: 2px;
  min-width: 50px;
  background: var(--Black-Black-70);
}

.video_views .video_views_count {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--theme-white);
  padding-top: 2px;
}

[dir='rtl'] .video_views {
  left: unset;
  right: 18px;
}

.video_profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-radius: 0.375rem;
}

.video_profile .video_profile--image {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid #454547;
  background: lightgray 50% / cover no-repeat;
}

.video_profile .video_profile--name {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  word-break: break-word;
}

.video_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.video_info {
  display: flex;
  min-height: 24px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 2px;
  background: rgba(23, 22, 25, 0.7);
  padding: 2px 6px;
  min-width: 35%;
}

.video_info--count {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding-top: 2px;
}

.play_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0 !important;
  z-index: 1;
}

.mute_unmute_button,
.play_pause_button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .button_sm {
    display: none !important;
  }
}

.play_pause_button svg {
  width: 14px;
  margin-top: 3px;
}
.mute_unmute_button svg {
  width: 24px;
  height: 24px;
}

.play_pause_button img,
.mute_unmute_button img {
  width: 32px;
  height: 32px;
  fill: var(--white);
}

.play_pause_button {
  left: 20px;
}

.mute_unmute_button {
  left: 60px;
}

.zoom_button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

[dir='rtl'] .zoom_button {
  right: unset;
  left: 20px;
}

@media screen and (max-width: 991px) {
  .mute_unmute_button {
    right: 20px;
    left: unset;
  }

  .zoom_button {
    display: none;
  }
}
.left_content {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  margin-top: 0;
  width: 350px;
  padding: 2.5rem 1.5rem;
  box-shadow: 0px 0px 62px var(--box-shadow-light);
  border-radius: 0px 40px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  z-index: 1;
}

/* User Profile */

.profile_img {
  width: 80px;
  height: 80px;
  position: relative;
  margin-bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
span.edit_icon {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--white);
  width: 30px;
  height: 30px;
  border-radius: 50px;
  padding: 5px 10px;
  color: var(--theme-color);
}
span.edit_icon svg {
  width: 15px;
  height: 15px;
}
.profile_img img {
  width: 100%;
  height: 100%;
}
.top_user_profile {
  margin-bottom: 2rem;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}
.top_user_profile ul {
  list-style: none;
  display: flex;
  gap: 15px;
  margin-top: 2rem;
  justify-content: center;
}
.top_user_profile ul li {
  color: #242424;
  position: relative;
  padding-right: 15px;
}
.top_user_profile ul li:after {
  display: inline-block;
  clear: both;
  content: '';
  background-color: #ddd;
  height: 20px;
  width: 1px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.top_user_profile ul li span {
  font-size: 14px;
}
.top_user_profile ul li:last-child {
  padding-right: 0;
}
.top_user_profile ul li:last-child::after {
  display: none;
}

/* Sidebar Menu */
.menu_wrapper ul {
  list-style: none;
}
.menu_wrapper ul li {
  margin-bottom: 15px;
}
.menu_wrapper ul li a {
  display: flex;
  align-items: center;
  color: #242424;
  gap: 15px;
}
.menu_wrapper ul li svg {
  width: 14px;
}

/* Create Content Button */
.create_btn {
  width: 80%;
  margin-top: 3rem;
}
.create_btn button {
  background-color: var(--theme-color);
  color: #fff;
  width: 100%;
  text-align: left;
  padding: 8px 10px 8px 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  border: none;
  align-items: center;
}
.create_btn button span {
  background: var(--white);
  color: var(--theme-color);
  border-radius: 50px;
  height: 26px;
  width: 26px;
  padding: 4px 7px;
}

/* Hastags Section */
.hashtags_wrapper {
  margin-top: 4rem;
}
.hashtags_wrapper h5 {
  font-size: var(--font-sz-md);
  font-weight: var(--font-wt-semibold);
  line-height: 24px;
  color: var(--primary-black);
}

.hastags_box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  max-height: 85px;
  overflow: hidden;
}
.hastags_box a {
  background: rgba(211, 226, 237, 0.5);
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-semibold);
  line-height: 21px;
  color: var(--primary-gray);
  padding: 8px 16px;
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 20px;
}

/* user information on post */
.user_info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px 0;
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
  overflow: visible;
  min-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.user_info_responsive {
  display: none;
}

@media screen and (max-width: 991px) {
  .user_info_responsive {
    display: block;
    z-index: 5;
  }

  .user_info_responsive .user_profile .user_name,
  .user_info_responsive .post_desc .title,
  .user_info_responsive .post_desc .desc {
    color: var(--theme-white);
  }

  .user_info_responsive .user_profile .user_name {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .user_info_responsive .post_desc .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .user_info_responsive .post_desc .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .post_icons button svg path {
    fill: var(--theme-white) !important;
  }

  .post_icons button.like_icon.active svg path {
    fill: #e03030 !important;
  }

  .post_icons button div:last-child {
    color: var(--theme-white) !important;
  }

  .post_icons button.mute_unmute_button {
    position: static;
    transform: unset;
    margin-bottom: 0;
  }
}

.user_info .top_info {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  margin-bottom: 16px;
}
.user_info .top_info,
.post_desc {
  padding: 0 20px;
}

.user_info .top_info.pr_6,
.post_desc.pr_6 {
  padding-right: 0 !important;
}

.img_name {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.img_name img {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid var(--borders-regular);
}

.user_profile .user_name {
  color: var(--secondary-black);
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-regular);
  line-height: 20px;
  margin: 0;
}
.user_profile .full_name {
  font-size: var(--font-sz-xxs);
  font-weight: var(--font-wt-regular);
  line-height: 16px;
  color: var(--secondary-black);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.top_info .follow_btn {
  font-size: var(--font-sz-xxs);
  font-weight: var(--font-wt-regular);
  line-height: 16px;
  color: var(--theme-color);
  background-color: transparent;
  padding: 0;
}

@media screen and (max-width: 576px) {
  .img_name {
    gap: 8px;
    align-items: center;
  }

  .img_name img {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  }

  .user_profile .full_name {
    display: none;
  }
}
.post_desc .title,
.post_desc .desc {
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-regular);
  color: var(--White-White-50);
  line-height: 20px;
  word-break: break-word;
}

.post_desc .desc {
  margin-top: 10px;
  margin-bottom: 0;
  word-break: break-word;
}
.post_desc .desc a {
  color: var(--secondary-black);
}
.post_desc .desc button {
  background: none;
  color: #fff;
  border: none;
}
.post_desc .desc button:hover {
  text-decoration: underline;
}

/* post icons */
.post_icons {
  display: none;
  gap: 20px;
  flex-direction: column;
  position: absolute;
  right: -60px;
  bottom: 0.5rem;
  color: var(--primary-black);
  transition: all 0.3s ease-in-out 0s;
  z-index: 10;
}
.post_icons.icons-transform {
  bottom: 6rem;
}
.post_icons button {
  border: 0;
  width: 48px;
  height: 48px;
  padding: 0px;
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-medium);
  color: var(--primary-black);
  line-height: 60px;
  cursor: pointer;
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.post_icons button:disabled {
  opacity: 1 !important;
  display: block !important;
}
.post_icons.post_active button {
  color: var(--theme-white);
}
.post_icons button.misc_icon {
  background-color: transparent !important;
}
.post_icons button.misc_icon svg {
  height: 20px;
}
.post_icons button.dropdown-toggle,
.post_icons button.misc_icon {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.post_icons .profile_thumbnail {
  width: 48px;
  padding: 0px;
  height: 48px;
  line-height: 58px;
  cursor: pointer;
  -o-object-fit: cover;
  object-fit: cover;
}
.post_icons .profile_thumbnail img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  -o-object-fit: cover;
  object-fit: cover;
}
.post_icons button svg {
  fill: var(--theme-color);
  width: 25px;
  height: 25px;
}
.post_icons .profile_thumbnail svg {
  width: 20px;
  height: 20px;
}
.post_icons button .icon-count {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-medium);
  line-height: 1;
}
.post_icons button .icon-count-number {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-medium);
  line-height: 1;
}
.post_icons button .icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.post_icons .shop_icon {
  margin-bottom: 0px;
}
.post_icons .shop_icon .icon {
  position: static;
  transform: none;
  margin-top: -2px;
}

.post_icons.post_active {
  right: 1rem;
}

.post_icons.show_post_icons {
  display: flex;
}

/* more options */
.misc_options_wrapper {
  position: relative;
}

[dir='rtl'] .misc_options_wrapper {
  margin-left: unset;
  margin-right: auto;
}

.misc_options_rotate {
  transform: rotate(90deg);
}
.contextMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  border: 2px solid #454547;
  background: var(--white);
  padding: 8px 16px;
  z-index: 1;
  top: 32px;
  min-width: 14rem;
}

.contextMenu .post-actions {
  white-space: nowrap;
  padding: 8px 0;
  color: var(--secondary-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 991px) {
  .contextMenu {
    top: unset;
    bottom: 55px;
    right: 0;
  }

  .comment-menu-toggle .contextMenu {
    bottom: unset;
  }

  .contextMenu .post-actions {
    width: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  .contextMenu .post-actions svg path {
    fill: var(--secondary-black) !important;
  }

  .contextMenu button.post-actions div {
    font-size: var(--font-sz-sm) !important;
    font-weight: var(--font-wt-regular) !important;
    line-height: 24px !important;
    color: var(--secondary-black) !important;
  }
}

.contextMenu a {
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-regular);
  line-height: 24px;
  color: var(--secondary-black);
  width: 100%;
}
.contextMenu .reply_btn.reply_actions {
  margin-bottom: 0;
  padding-bottom: 0;
}
.video_expanded .dropdown-menu,
.misc_options_wrapper .dropdown-menu {
  list-style: none;
  position: absolute;
  background: var(--white);
  width: 12rem;
  left: 0;
  padding: 10px;
  border-radius: 5px;
  border: 0;
}
.contextMenu a .btn-wrap svg {
  flex: 0 0 24px;
}

.contextMenu .btn-wrap {
  padding: 8px 0;
}
.contextMenu a .btn-wrap p {
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-regular);
  line-height: 24px;
  color: var(--secondary-black);
}
.video_expanded .dropdown-menu a,
.misc_options_wrapper .dropdown-menu a {
  color: var(--primary-black);
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-semibold);
  outline: 0;
}
.video_expanded .dropdown-menu a div:first-child,
.misc_options_wrapper .dropdown-menu a div:first-child {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding: 1px;
}
.misc_options_wrapper ul li:last-child {
  margin-bottom: 0px;
}
.video_expanded .dropdown-menu svg,
.misc_options_wrapper .dropdown-menu svg {
  fill: var(--primary-black);
}
.video_expanded .btn-primary,
.video_expanded .dropdown-toggle,
.video_expanded .btn-primary:active:hover,
.video_expanded .btn-primary:active:focus,
.video_expanded .dropdown-toggle :focus {
  background-color: transparent;
  border-color: none;
  outline: none;
  border: none;
  box-shadow: none;
  background-image: unset;
}
.video_expanded .misc_icon {
  background: none;
  border: none;
}
.video_expanded .misc_icon path {
  fill: var(--text-tertiary);
}

.video_expanded .comment_info .dropdown-toggle {
  padding: 0 8px;
}

.video_expanded .comment_info .dropdown-toggle .misc_icon svg {
  height: 20px;
}

/* comment section */
.comment_wrapper .close_button {
  border-radius: 50%;
  padding: 1px;
  width: 30px;
  height: 30px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -4px;
  top: -6px;
}
.comment_wrapper .close_button svg {
  fill: var(--white);
  width: 45px;
  height: 45px;
  flex: 0 0 45px;
}
[dir='rtl'] .comment_wrapper .close_button {
  right: unset;
  left: 0;
}
.top_content {
  display: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  position: relative;
}
@media screen and (max-width: 991px) {
  .top_content {
    display: flex;
    margin-bottom: 15px;
    padding: 0 20px;
    justify-content: flex-start;
  }
}
.top_content h5 {
  font-size: var(--font-sz-sm);
  color: var(--secondary-black);
  font-weight: var(--font-wt-regular);
  line-height: 24px;
  text-align: center;
}

.reply_content .comment_info {
  margin-top: 16px;
  position: relative;
}

.reply_content .comment_info .left .content h6 span {
  margin-top: 16px;
  padding-left: 0;
}

.show-reply {
  display: block;
}

.hide-reply {
  display: none;
}

.comment_info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.comment_info .content h6,
.comment_info .content a {
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-medium);
  line-height: 20px;
  color: var(--theme-color);
}
.comment_info .content h6 span {
  margin-top: 0;
  display: inline;
  font-weight: var(--font-wt-regular);
  line-height: 20px;
  color: var(--theme-default-color);
  padding-left: 12px;
  word-break: break-word;
}

.comment_info .content h6 {
  margin-bottom: 0;
}
.comment_info .content p {
  color: var(--secondary-black);
  font-size: var(--font-sz-xs);
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
  word-break: break-word;
}
.comment_info .content .comment_actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.comment_info .content .comment_actions:empty {
  display: none;
}
.comment_info .content .comment_actions button {
  background: none;
  border: none;
  color: var(--primary-gray);
}
.comment_info .content .comment_actions button img {
  width: 15px;
  height: 15px;
  opacity: 0.7;
  transition: 0.3s;
}
.comment_info .content .comment_actions .delete_btn {
  border-left: 2px solid #ddd;
  padding-left: 10px;
}

.delete_btn.reply_btn .btn-wrap p {
  color: #e03030 !important;
}
.comment_info .content .comment_actions button:hover {
  text-decoration: underline;
  color: var(--theme-color);
  cursor: pointer;
  transition: all 0.3s ease;
}
.comment_info .content button {
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-regular);
  line-height: 20px;
  color: var(--White-White-50);
  background: none;
  border: none;
  position: relative;
}
.comment_info .left {
  display: flex;
  gap: 12px;
  flex: 1;
}
.comment_info .left .content {
  flex: 1;
  padding-right: 24px;
}

.comment_info .left .content .content_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 2px;
}

.comment_info .left .content .content_info h4 {
  color: var(--theme-default-color);
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-medium);
  line-height: 20px;
}

.comment_info .left .content .content_info span {
  color: var(--White-White-50);
  font-size: var(--font-sz-xxs);
  font-weight: 400;
  line-height: 16px;
}

.comment_info .left .content .comment_meta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

[dir='rtl'] .comment_info .left .content {
  padding-right: 0;
  padding-left: 0;
}

[dir='rtl'] .comment-menu-toggle {
  right: unset;
  left: -10px;
}
.comment_info .img img {
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid var(--White-White-20);
}

@media screen and (max-width: 576px) {
  .comment_info .img img {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  }
}
.comment_info .view_replies {
  padding-left: 45px;
  margin-top: 5px;
  color: var(--secondary-black) !important;
}
.comment_info .view_replies:before {
  display: inline-block;
  clear: both;
  content: '';
  height: 1px;
  width: 32px;
  background-color: var(--White-White-50);
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  left: 16px;
}

@media screen and (max-width: 576px) {
  .comment_info .view_replies:before {
    background-color: var(--secondary-black);
  }
}

.comment_wrapper {
  height: 0;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 991px) {
  .comment_wrapper {
    overflow: visible;
  }
}

.comment_wrapper.commentExpanded {
  overflow-y: auto;
  padding-top: 12px;
  border-top: 1px solid var(--White-White-10);
}

@media screen and (max-width: 991px) {
  .comment_wrapper.commentExpanded {
    height: auto;
    overflow: visible;
    border-top: 0;
  }
}

[dir='rtl'] .comments_list {
  padding-right: 0;
  padding-left: 10px;
}

/* width */
.comments_list::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
}

/* Track */
.comments_list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
.comments_list::-webkit-scrollbar-thumb {
  background: var(--secondary-gray);
  border-radius: 6px;
}

.view-more-comments {
  font-size: var(--font-sz-xs);
  font-weight: var(--font-wt-regular);
  line-height: 20px;
  color: var(--White-White-50);
  background: none;
  border: none;
  position: relative;
  padding-left: 45px;
  margin-left: 34px;
}

.view-more-comments:before {
  display: inline-block;
  clear: both;
  content: '';
  height: 1px;
  width: 32px;
  background-color: var(--White-White-50);
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  left: 16px;
}

[dir='rtl'] .view-more-comments {
  margin-left: 0;
  margin-right: 60px;
}

.view-more-comments .comment-count {
  color: var(--secondary-black);
}

.comment_input {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: var(--white);
}

.comment_input .left {
  flex: 0 0 48px;
}

.comment_input .left img {
  width: 48px;
  height: 48px;
  -o-object-fit: cover;
  object-fit: cover;
}
.comment_input .input_field {
  position: relative;
  flex: auto;
}
.comment_input .input_field .input_row {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid var(--White-White-20);
}
.comment_input .input_field input,
.comment_input .input_field textarea {
  width: 100%;
  height: 48px;
  background: #f9f9f9;
  border: 0.5px solid #d4e3ed;
  border-radius: 24px !important;
  padding-left: 15px;
  padding-right: 50px;
  box-shadow: none;
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-semibold);
  color: var(--primary-gray);
  padding-top: 16px;
  padding-bottom: 16px;
  outline: 0;
  line-height: 24px;
}

.comment_input .input_field textarea {
  height: auto;
  resize: none;
  padding: 12px 0;
  overflow-y: auto;
  max-height: 95px;
  display: block;
  float: unset;
  border-radius: 0 !important;
  background: transparent;
  border: 0;
  font-size: var(--font-sz-xs);
  font-weight: 400;
  line-height: 20px;
  color: var(--secondary-black);
  caret-color: var(--theme-color);
}

[dir='rtl'] .comment_input .input_field textarea {
  text-align: right;
}

@media screen and (max-width: 991px) {
  .comment_input .input_field textarea {
    max-height: 45px;
    padding-right: 20px;
    border-color: var(--White-White-20);
  }

  .comment_input {
    padding: 10px 20px 0;
  }
}

/* width */
.comment_input .input_field textarea::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
  display: none;
}

/* Track */
.comment_input .input_field textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
  display: none;
}

/* Handle */
.comment_input .input_field textarea::-webkit-scrollbar-thumb {
  background: var(--secondary-gray);
  border-radius: 6px;
}

.comment_input .input_field button {
  z-index: 9;
  background: none;
  padding: 8px 0 8px 24px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.comment_input .input_field button svg {
  width: 25px;
  height: 25px;
}

.comment_input .input_field button.themeColorBtn svg path {
  fill: var(--theme-color);
}
.comment_input .input_field .input-group {
  width: 100%;
  display: flex;
}

/* product wrapper */
.product_info {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  height: 100%;
  border-radius: 4px;
  background: var(--surface-gray-10, #e8e8e8);
}
.product_info .left {
  width: 56px;
  height: 56px;
  flex: 0 0 56px;
  border-radius: 4px;
  background: #fff;
  padding: 8px;
}
.product_info img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.product_info .right {
  color: #ffffff;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-right: 20px;
}

.product_info .right .product-link-arrow {
  position: absolute;
  right: 0;
}

[dir='rtl'] .product_info .right {
  padding-right: 0;
  padding-left: 20px;
}

[dir='rtl'] .product_info .right .product-link-arrow {
  right: unset;
  left: 0;
  transform: rotate(180deg);
}

.product_info .right h6 {
  margin: 0 0 2px 0;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.product_info .right .price {
  color: #e03030;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.product_info button,
.product_info .product-link {
  border: none;
  border-radius: 2px;
  background: var(--theme-color);
  box-shadow: none;
  outline: none;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 100%;
}
.product_container {
  width: 100%;
  display: flex;
  overflow-x: auto;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  gap: 5px;
  position: relative;
  margin: 16px 0 8px 20px;
  padding-right: 40px;
  z-index: 10;
  scroll-behavior: smooth;
}
.product_container:active {
  cursor: grab;
}
/* width */
.product_container::-webkit-scrollbar {
  display: none;
}

/* Track */
.product_container::-webkit-scrollbar-track {
  display: none;
}
/* Handle */
.product_container::-webkit-scrollbar-thumb {
  background: #888;
}
.product_wrapper {
  scroll-snap-stop: always;
  scroll-snap-align: start;
  width: 100%;
  height: 100%;
  min-width: 100%;
}

@media screen and (max-width: 991px) {
  .product_wrapper {
    min-width: 70%;
    max-width: 75%;
  }
}

/* Share icon */
.share_wrapper .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-bottom: none;
  padding: 10px 16px 10px;
}
.share_wrapper .modal-header::before {
  display: none;
}
.share_wrapper .modal-header .modal-title {
  text-align: left;
}
.share_wrapper .modal-header .modal-title span {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: #1c1d1d;
}
.share_wrapper .modal-header button {
  border: none;
  background: var(--primary-black);
  border-radius: 50%;
  position: absolute;
  right: 8px;
  top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
}
.share_modal-rtl.share_wrapper .modal-header button {
  right: unset;
  left: 8px;
}
.share_wrapper .modal-header button svg {
  width: 45px;
  height: 45px;
  flex: 0 0 45px;
}
.share_wrapper .modal-header button i {
  font-size: 30px;
}
.share_wrapper .share_content button {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  border: 0;
}
.share_wrapper .share_content button svg {
  width: 100%;
  height: 100%;
}

.share_wrapper .share_content button i {
  color: #fff;
  font-size: 30px;
  line-height: 40px;
}
.share_wrapper .share_content span {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  color: var(--primary-black);
}
.share_wrapper.modal {
  padding-left: 30rem !important;
}
.share_wrapper .modal-dialog {
  width: 100%;
  max-width: 420px;
}
.share_wrapper .modal-dialog .modal-content {
  width: 90%;
}
.share_wrapper .share_content {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
}
.share_wrapper .share_content div {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.share_modal-rtl.share_wrapper.modal {
  padding-left: 0 !important;
  padding-right: 30rem !important;
}

.share_wrapper.repost_modal .modal-close {
  z-index: 10;
}

.share_wrapper.repost_modal .repost_actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.share_wrapper.repost_modal .repost_actions .repost_actions-btn {
  border: 0;
  background: none;
}

.share_wrapper.repost_modal .repost_actions .repost_actions-btn .btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

.share_wrapper.repost_modal .repost_actions .repost_actions-btn .btn-wrap span {
  width: 58px;
  height: 58px;
}
.toast_text,
.share_wrapper.repost_modal .repost_actions .repost_actions-btn .btn-wrap div {
  font-size: var(--font-sz-sm);
  font-weight: var(--font-wt-semibold);
  line-height: 20px;
}

/* Arabic Styling */

[dir='rtl'] .left_content {
  padding: 2rem;
  left: unset;
  right: 0;
  border-radius: 40px 0px 0px 0px;
}
[dir='rtl'] .profile_img {
  left: unset;
  right: 20%;
}
[dir='rtl'] .create_btn button {
  padding: 8px 20px 8px 7px;
}
[dir='rtl'] .hastags_box a {
  margin-right: unset;
  margin-left: 10px;
}
[dir='rtl'] .comment_input {
  flex-direction: row;
}
[dir='rtl'] .next-btn,
[dir='rtl'] .prev-btn {
  left: unset;
  right: 120px;
}
[dir='rtl'] .play_pause_button {
  right: 20px;
  left: unset;
}
[dir='rtl'] .mute_unmute_button {
  right: 60px;
  left: unset;
}
[dir='rtl'] .sidebar-ul {
  text-align: right;
}
[dir='rtl'] .sidebar-ul li a {
  display: flex;
  gap: 10px;
}
[dir='rtl'] .followers-info.profile-page-head-content-inner li:nth-child(3) {
  padding-right: 15px;
  border-left: unset;
}
[dir='rtl'] .sidebar {
  text-align: right;
}
[dir='rtl'] .sidebar .content-btn {
  padding: 5px 18px 5px 5px;
}
[dir='rtl'] .sidebar .content-btn img {
  margin-left: unset;
  margin-right: 10px;
}
[dir='rtl'] .post_icons {
  left: -60px;
  right: unset !important;
  transition: all 0.5s ease;
}

[dir='rtl'] .post_icon_rtl {
  left: 1rem;
  transition: all 0.5s ease;
}
[dir='rtl'] .comment_input .input_field button {
  left: 2px;
  right: unset;
  transform: rotate(180deg);
}
[dir='rtl'] .comment_input .input_field input {
  padding-right: 20px;
}

[dir='rtl'] .followers-info.profile-page-head-content-inner li:first-child {
  padding-left: 15px;
}
[dir='rtl'] .sidebar-ul li svg {
  padding-right: 0px;
  height: 18px !important;
}
[dir='rtl'] .product_container {
  margin: 16px 20px 0 0;
  padding-right: 0;
  padding-left: 60px;
}
[dir='rtl'] .product_container .product_wrapper {
  scroll-snap-align: end;
}
.sidebar .content-btn {
  position: unset;
  bottom: unset;
}

.mid-content .next-btn,
.mid-content .prev-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
}

.mid-content button:disabled {
  opacity: 0.5;
}

[dir='rtl'] .main-create {
  width: calc(100% - 300px);
  margin-left: 0;
  margin-right: 300px;
}

.directionRtl {
  background-color: var(--white);
}
[dir='rtl'] .directionRtl {
  right: 0;
  left: unset;
}

[dir='rtl'] .contextMenuArabic {
  left: 0;
  right: unset;
}

@media screen and (min-width: 1600px) {
  [dir='rtl'] .main-create {
    width: calc(100% - 350px);
    margin-left: 0;
    margin-right: 350px;
  }
}

@media screen and (min-width: 769px) {
  .video_player.video-player-radius,
  .video_controls.video-player-radius {
    border-radius: 16px 0 0 16px;
    transition: border-radius 0.5s ease-in-out;
  }

  .video_controls.video-player-radius + .user_info {
    border-radius: 0 0 0 16px;
    transition: border-radius 0.5s ease-in-out;
  }

  [dir='rtl'] .video_player.video-player-radius,
  [dir='rtl'] .video_controls.video-player-radius {
    border-radius: 0 16px 16px 0;
    transition: border-radius 0.5s ease-in-out;
  }

  [dir='rtl'] .video_controls.video-player-radius + .user_info {
    border-radius: 0 0 16px 0;
    transition: border-radius 0.5s ease-in-out;
  }
}

@media screen and (max-height: 600px) {
  .comment_info .content h6,
  .comment_info .content h6 span,
  .comment_info .content p {
    font-size: var(--font-sz-xs);
    line-height: 1.2;
    margin-bottom: 4px;
  }

  .video_expanded {
    padding: 10px;
  }
  .video_expanded .video_expanded_head {
    padding-top: 10px;
  }
  .video_expanded .img_name {
    gap: 10px;
  }

  .video_expanded .user_info .top_info {
    margin-bottom: 10px;
  }
  .video_expanded .user_info .post_desc {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1600px) {
  .left_content {
    width: 300px;
    padding: 2.5rem 1rem;
  }
}

@media screen and (max-width: 991px) {
  .mid-content .next-btn,
  .mid-content .prev-btn {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .left_content {
    display: none;
  }

  [dir='rtl'] .video_expanded {
    border-radius: 16px 16px 0 0;
  }

  .mid-content,
  [dir='rtl'] .mid-content {
    width: 100%;
    max-width: 100dvw;
    margin-left: 0;
    margin-right: 0;
  }
  .share_wrapper .share_content button {
    width: 48px;
    height: 48px;
  }
  .player_wrapper {
    padding-bottom: 0;
    padding-left: 0;
  }

  [dir='rtl'] .player_wrapper {
    padding-right: 0;
  }

  .player_item {
    width: 100%;
    margin-bottom: 0;
    margin-left: 0px !important;
  }
  .player_wrapper .next-btn,
  .player_wrapper .prev-btn {
    display: none;
  }

  .player_item .post_icons {
    right: 1rem !important;
    gap: 16px;
  }
  .post_icons button {
    width: 40px;
    height: 40px;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .post_icons button.zoom_button {
    transform: none;
    position: static;
    margin-bottom: -10px;
  }
  .post_icons .profile_thumbnail {
    width: 40px;
    height: 40px;
    line-height: 50px;
  }
  .post_icons button svg {
    width: 24px;
    height: 24px;
  }
  .post_icons button div:last-child {
    color: var(--secondary-black);
    top: 38px;
  }

  .next-btn,
  .prev-btn {
    display: none;
  }

  .video_expanded {
    position: fixed !important;
    z-index: 999;
    left: 0;
    right: 0;
    border-radius: 16px 16px 0 0;
    max-width: 100dvw;
    width: 100%;
    height: 90%;
    background: var(--white);
  }
  .video_expanded_hidden {
    bottom: -100%;
    transform: translateX(0px);
  }
  .video_expanded_visible {
    bottom: -75px;
  }
  #signupmodel {
    width: 100%;
  }
  .comment_wrapper {
    height: calc(var(--default-screen-h) - 150px);
  }
  .comment_modal .modal-content {
    height: 98%;
  }
  .comment_modal .comment_wrapper {
    padding: 10px;
  }
  .comment_modal .btn-primary {
    background-image: none;
    border: none;
    background: none;
    padding: 0px;
  }
  .comment_modal button.misc_icon {
    border: none;
    background: none;
    text-shadow: none;
    box-shadow: none;
    border-radius: none;
  }
  .comment_modal button.misc_icon svg path {
    fill: #000;
  }
  .comment_input .input_field {
    width: 82%;
  }

  .comments_list {
    overflow-y: auto;
    height: calc(var(--default-screen-h) - 165px);
    border-bottom: 1px solid var(--White-White-20);
    border-top: 1px solid var(--White-White-20);
    padding: 20px;
  }
  .share_wrapper.modal.fade .modal-dialog {
    transform: translate(0, 100%);
  }
  .share_wrapper.modal.show .modal-dialog {
    transform: none;
  }
  .share_wrapper.modal,
  .modal-open .modal {
    padding-left: 0 !important;
    height: 100dvh;
  }
  .share_wrapper .modal-dialog {
    width: 100% !important;
    max-width: 100%;
    margin: 0;
    padding: 0;
    top: unset;
    bottom: 0;
    position: absolute;
    height: auto;
  }
  .share_wrapper .modal-dialog .modal-content {
    border-radius: 6px 6px 0 0;
    border: 0;
    width: 100%;
  }

  .share_modal-rtl.share_wrapper.modal .modal-dialog {
    width: 100% !important;
  }

  [dir='rtl'] .post_icons {
    right: unset !important;
    left: 1rem !important;
  }

  .row-responsive {
    margin-right: 0;
    margin-left: 0;
  }

  .row-responsive::before,
  .row-responsive::after {
    display: none;
  }

  .player_wrapper .player_item_wrapper {
    margin: 0;
    height: var(--default-screen-h);
    position: relative;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    padding: 0;
  }

  .player_item {
    height: calc(var(--default-screen-h) - 7px);
  }

  .player_item_wrapper .video_player,
  .video_controls,
  .user_info {
    border-radius: 0;
  }

  .user_info .top_info,
  .post_desc {
    padding: 0 60px 0 20px;
  }

  [dir='rtl'] .user_info .top_info,
  [dir='rtl'] .post_desc {
    padding: 0 20px 0 60px;
  }

  .post_icons_responsive {
    bottom: 6rem;
  }

  .comment_input .input_field .input_row {
    position: relative;
  }

  .comment_input .input_field .input_row #button-addon2 {
    background-color: transparent;
    padding: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  .comment_wrapper .close_button {
    border: 0;
    right: 12px;
  }
}

@media screen and (max-width: 576px) {
  .user_info .top_info {
    margin-bottom: 12px;
    gap: 8px;
    align-items: center;
  }
  .user_profile .user_name {
    line-height: 20px;
  }
  .post_desc .title {
    font-size: var(--font-sz-xs);
    line-height: 20px;
  }
  .post_desc .desc {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .top_info .follow_btn {
    font-size: 12px;
    line-height: 16px;
  }
  .comment_info .content h6,
  .comment_info .content h6 span,
  .comment_info .content p {
    font-size: var(--font-sz-xs);
  }

  .post_icons button div:last-child {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .video_expanded {
    padding: 10px 0;
  }
}

/* Styling For Small Screen Height  */
@media screen and (max-height: 600px) {
  .post_icons button {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 4px;
  }

  .post_icons button svg {
    width: 20px;
    height: 20px;
  }

  .post_icons button div:last-child {
    top: 42px;
  }

  .post_icons .profile_thumbnail {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .user_info .top_info {
    gap: 10px;
  }

  .user_profile .user_name {
    font-size: var(--font-sz-sm);
    line-height: 20px;
  }

  .top_info .follow_btn {
    font-size: var(--font-sz-xs);
    line-height: 16px;
  }
}
.repost_text {
  padding-left: 18px;
  margin-bottom: 6rem;
  position: relative;
  z-index: 99;
}

[dir='rtl'] .repost_text {
  padding-right: 18px;
}
@media screen and (max-width: 991px) {
  .repost_text {
    padding-left: 1rem;
    margin-bottom: 2rem;
  }
}
.repost_text button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px 6px;
  border-radius: 2px;
  background: var(--Black-Black-70);
  /* background-color: transparent; */
  color: var(--theme-white);
  font-size: 14px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}
.repost_text span {
  display: flex;
  align-items: center;
}
.repost_text svg {
  width: 18px;
}
.repost_text svg path {
  fill: var(--theme-white) !important;
}
.repost_text .dash {
  color: #fff;
}
.player_wrapper .profile-head-back {
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
  z-index: 1;
  padding-left: 50px;
}

.player_wrapper .profile-head-back button {
  text-decoration: none;
  font-size: var(--font-sz-md);
  font-weight: var(--font-wt-semibold);
}

[dir='rtl'] .player_wrapper .profile-head-back {
  padding-left: 0;
  padding-right: 50px;
}

[dir='rtl'] .player_wrapper {
  padding-left: 0;
  padding-right: 300px;
}

@media screen and (min-width: 1600px) {
  [dir='rtl'] .player_wrapper {
    padding-right: 350px;
  }
}
@media screen and (max-width: 576px) {
  [dir='rtl'] .player_wrapper {
    padding-right: 0;
  }
  [dir='rtl'] .main-create {
    margin-right: 0;
    width: 100%;
  }
  .comment_info .content h6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: var(--font-wt-regular);
    color: var(--text-tertiary);
  }
  .comment_info .content h6 span {
    font-size: 14px;
    padding-left: 0;
    color: var(--secondary-black);
    line-height: 20px;
  }
  .comment_info .content .reply_btn {
    margin-bottom: 0 !important;
    font-size: var(--font-sz-xs);
    font-weight: var(--font-wt-regular);
    line-height: 20px;
    color: var(--White-White-50);
  }

  .comment_info .content p {
    font-size: var(--font-sz-xs);
    font-weight: var(--font-wt-regular);
    line-height: 20px;
    color: var(--secondary-black);
  }

  .comment_info .content button {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--secondary-black);
  }

  .comment_info .content button.view_replies {
    padding-left: 45px;
  }

  .comment_info .left .content .comment_meta {
    margin-top: 12px;
  }

  .comment_info .left .content .comment_meta p {
    display: none;
  }

  .comment_info .left .content .reply_content .content p {
    margin-top: 4px;
  }

  .comment_info .left .content .reply_content .content .reply_btn {
    margin-top: 8px;
  }

  .comment_info .content button.show-more-toggle {
    color: var(--primary-gray);
  }

  .reply_content .comment_info .left .content h6 span {
    margin-top: 0;
    margin-bottom: 0;
  }

  .player_item {
    border-radius: 0;
  }
}

@media screen and (max-width: 991px) {
  .main-create {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 12px 20px;
  }
  .main-create .main-create-menu {
    width: 100%;
  }

  .main-create .main-create-menu:nth-child(2) {
    display: none;
  }
}

.video-progress-container {
  width: calc(100% - 40px);
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 12px;
  margin: 0 20px;
  align-self: stretch;
}

.video-progress-bar {
  height: 2px;
  background: #fff;
}
