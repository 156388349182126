@import url(./Variables.css);

body.dark {
  --primary-black: #dddddd;
  --white: #171619;
  --black: #dddddd;
  --box-shadow-light: #0000009e;
  --primary-gray: #b9b9ba;
  --secondary-black: #ffffff;
  --text-tertiary: #8b8a8c;
  --theme-default-color: #e95f2a;
  --borders-regular: #454547;

  --White-White-70: rgba(255, 255, 255, 0.7);
  --White-White-50: rgba(255, 255, 255, 0.5);
  --White-White-20: rgba(255, 255, 255, 0.2);
  --White-White-10: rgba(255, 255, 255, 0.1);
  --White-White-05: rgba(255, 255, 255, 0.05);
}

body.dark {
  background-color: rgba(23, 22, 25);
}

body.dark .sidebar .content-btn {
  color: var(--theme-white);
}

body.dark .reply_btn .btn-wrap span svg {
  fill: var(--primary-gray);
}
